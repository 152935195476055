body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --amplify-primary-color: #e95725;
  --amplify-white: #002f37;
  --amplify-grey: #ffffff;
  --amplify-light-grey: #ffffff;
  --amplify-secondary-color: rgb(255, 255, 255);
  --amplify-text-xxs: 1rem;
  --amplify-text-xs: 1.06rem;
  --amplify-text-sm: 1.125rem;
  --amplify-text-md: 1.25rem;
  --amplify-text-lg: 1.75rem;
  --amplify-text-xl: 2.25rem;
  --amplify-text-xxl: 2.75rem;
  --amplify-secondary-tint: #e95725;
}

amplify-authenticator {
  --container-height: 600px;
  --background-color: '#fff';
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
}

#root {
  height: 100%;
}