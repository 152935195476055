.header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 110px;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  font-family: 'Lato', 'Helvetica Neue', sans-serif;
  background-color: #002f37;
  width: 100%;
  margin: auto;
  border-bottom: 3px solid #e37f5f;
}

.tags {
  text-decoration: none;
  color: #fff;
}
.tags:hover {
  color: #e8572a;
}

ul {
  width: 30%;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  list-style: none;
  align-items: center;
  justify-content: space-evenly;
  padding: 0;
}
