.App {
  text-align: center;
  width: 100%;
}

.App-logo {
  margin: 0 auto;
  display: flex;
  pointer-events: none;
  height: 100px !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ui.search .prompt {
  border-radius: 4px !important;
}

.picker {
  width: 100% !important;
}

.field .field {
  display: unset !important;
}